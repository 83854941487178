import React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, Clock, ExternalLink } from 'react-feather'
import { Tabs, Tab } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import WorldMap from "../../components/worldmap"
import  Stephanie  from '../../images/stephanie_brennan.png'

export default class Lithuania extends React.Component {
  constructor(props){
      super(props);

      this.state = {
          showEcosystemsLink: false,
          showNextLink: false,
          showPrevLink: false
      }

      this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
      // Esc key press
      if(event.keyCode === 27) {
          this.setState({
              showEcosystemsLink: true
          });

          setTimeout(() => {
              let linkRef = document.getElementById("esc-action");
              linkRef.click();
          }, 50)
      }

      // Left Arrow key press
      if(event.keyCode === 37) {
          this.setState({
              showPrevLink: true
          });

          setTimeout(() => {
              let prevLinkRef = document.getElementById("prev-action");
              prevLinkRef.click();
          }, 50)
      }

      // Right Arrow key press
      if(event.keyCode === 39) {
          this.setState({
              showNextLink: true
          });

          setTimeout(() => {
              let nextLinkRef = document.getElementById("next-action");
              nextLinkRef.click();
          }, 50)
      }
  }
  componentDidMount(){
      document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
      return (
  <Layout>
            {
            this.state.showEcosystemsLink &&
            <AniLink id="esc-action" fade to="/startup-visas" duration={0.4}></AniLink >
        }
        {
            this.state.showPrevLink &&
            <AniLink id="prev-action" fade to="/startup-visas/latvia" duration={0.4}></AniLink >
        }
        {
            this.state.showNextLink &&
            <AniLink id="next-action" fade to="/startup-visas/finland" duration={0.4}></AniLink >
        }
    <SEO title="Lithuania's Startup Visa" />
    <div id="top-part">
    <div id="back">
        <AniLink fade to="/startup-visas" duration={0.4}>
          <em>{'<-'}</em> All Startup Visas
        </AniLink>
      </div>
    <div id="contains-map">
      <div id="lithuania_map_container">
      <WorldMap />
      </div>
      </div>
      <div id="left-col">
        <div id="left-col-content">
          <div className="page-icon-container" style={{fontSize:'48px'}}>
          <span role="img" aria-label="Lithuania">🇱🇹</span>
          </div>
          <h1 id="country-header">Lithuania's Startup Visa</h1>
        </div>
      </div>
      <div id="right-col">
      
        {/* <AniLink fade to="/interviews/lithuania" className="colled">
          <div className="long-container">
            <div className="item-header">
            <Coffee className="slack-icon"/> 
            Interview with Program Manager
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </AniLink> */}
        <AniLink fade to="/ecosystems/lithuania" className="colled-many" duration={0.4}>
        <div className="long-container">
            
            <div className="item-header">
            <div className="long-icon-container">
                <Globe className="slack-icon"/> 
            </div>
            Ecosystem
            <div className="item-arrow">
                →
            </div>
            </div>
            <div className="item-description">
            </div>

        </div>
        </AniLink>
        <OutboundLink href="https://startupvisalithuania.com/" target="_blank"  rel="noopener noreferrer">
          <div className="external-container">
            
            <div className="item-header">
              <div className="long-icon-container">
                <Globe className="slack-icon"/> 
              </div>
            Official Website
              <div className="item-arrow">
              <ExternalLink className="slack-icon"/> 
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </OutboundLink>
        <OutboundLink href="https://startupvisalithuania.com/login" target="_blank" rel="noopener noreferrer">
          <div className="external-container">
            
            <div className="item-header">
              <div className="long-icon-container">
                <Globe className="slack-icon"/> 
              </div>
              Apply Now
              <div className="item-arrow">
              <ExternalLink className="slack-icon"/> 
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </OutboundLink>
      
    
      </div>
    </div>
    <div className="content-container">

    <Tabs defaultActiveKey="visa-description" id="country-tabs">
    <Tab eventKey="visa-description" title="Visa Information" id="visa-information-tab">
      <p>Designed for innovative startup founders who wish to establish a startup in Lithuania, you no longer need to fulfill certain capital or employment requirements to obtain a residence permit. If your business idea is deemed suitable by our panel of experts, you will be able to apply for a temporary residence permit on such basis.</p>
    <p>
    The Startup Visa gives you access to a streamlined migration procedure in claiming a temporary residence permit in Lithuania. A temporary residence permit will allow you to live in the country for one year, with the possibility to extend for two additional years. You will also be allowed to bring along your family. After 3 years, in order to stay in Lithuania, you will have to fulfill regular immigration requirements.
    </p>
      <table className="country-table">
        <tr>
          <td className="table-label">Degree Required</td>
          <td className="table-value">Not Required</td>
        </tr>
        <tr>
          <td className="table-label">Visa Duration</td>
          <td className="table-value">12 months</td>
        </tr>
          <tr>
          <td className="table-label">Bootstrappers</td>
          <td className="table-value">Yes</td>
        </tr>
          <tr>
          <td className="table-label">Visa Fees</td>
          <td className="table-value">$168</td>
        </tr>
          <tr>
          <td className="table-label">Personal Funds</td>
          <td className="table-value">$8,173</td>
        </tr>
          <tr>
          <td className="table-label">Minimum Investment</td>
          <td className="table-value">$0</td>
        </tr>
          <tr>
          <td className="table-label">Processing Time</td>
          <td className="table-value">60 days</td>
        </tr>
      </table>
    </Tab>
    <Tab eventKey="real-reviews" title="Founder Reviews" id="visa-information-tab">
      <div className="reviews-container">
        <AniLink fade to="interviews/evarvest" duration={0.4}>
          <div className="review-container">
              <div className="review-description">
                <p> My first interview for this visa was after submitting my application in April of 2018. I had already moved to Lithuania, as an Australian I had a 90 day visa-free grace period in Lithuania which was helpful. I landed in Lithuania and then picked up my visa because you have to submit everything formally when you're in Lithuania. I got mine that September.</p>
              </div>
              <div className="interview-details">
                <div className="left-details">
                <div className="review-image">
           <img src={Stephanie} alt="Logo" style={{'transform':'scale(1.2)'}} />
          </div>
                <div className="job-company">
                 Stephanie Brennan
                </div>
                <div className="job-tags">
                  <div className="job-tag">
                    <div className="job-tag-text">
                      Founder
                    </div>
                  </div>
                  </div>
                </div>
                <div className="right-details">
                  <div className="job-item">
                    <div>
                      <Clock className="job-icon"/>
                    </div>
                    Received in 4 months
                  </div>
                </div>
              </div>
          </div>
          </AniLink>
      </div>
    </Tab>
  </Tabs>
    </div>
  </Layout>
)
  }
}